<template>
  <div class="footer py-5">
    <img src="@/assets/images/logo.svg" alt="" />
  </div>
</template>
<style lang="scss">
.footer {
  padding: 5rem auto;
  margin-top: 4rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -3px 14px -4px #00000055;
  text-align: center;
}
</style>
